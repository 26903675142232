$dropdown-link-danger-color: $component-active-color;
$dropdown-link-danger-bg: $danger;

$dropdown-link-danger-hover-color: shade-color($dropdown-link-danger-color, 10%);
$dropdown-link-danger-hover-bg: shade-color($dropdown-link-danger-bg, 10%);

.dropdown-item {
  --#{$prefix}dropdown-link-danger-hover-color: #{$dropdown-link-danger-hover-color};
  --#{$prefix}dropdown-link-danger-hover-bg: #{$dropdown-link-danger-hover-bg};
  --#{$prefix}dropdown-link-danger-color: #{$dropdown-link-danger-color};
  --#{$prefix}dropdown-link-danger-bg: #{$dropdown-link-danger-bg};

  &.danger {
    color: var(--#{$prefix}dropdown-link-danger-color);
    text-decoration: none;
    @include gradient-bg(var(--#{$prefix}dropdown-link-danger-bg));

    &:hover, &:focus {
      color: var(--#{$prefix}dropdown-link-danger-hover-color);
      @include gradient-bg(var(--#{$prefix}dropdown-link-danger-hover-bg));
    }
  }
}

.form-check-input[indeterminate=true] {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 2px;
    background-color: $black;
    transform: translate(-50%, -50%);
  }
}
