/* You can add global styles to this file, and also import other style files */

$zindex-dropdown: 5000;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-extensions";

[tabindex]:not([role=dialog]) {
  cursor: pointer;
}

.btn-icon {
  border: none;
  outline: none;
  background: none;
}

.font-italic {
  font-style: italic;
}
